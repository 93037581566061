



































































































































.detail-ticket {
  width: 100%;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    box-shadow: 0px 3px 5px -2px #00000075;
    .text {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      text-transform: uppercase;
      width: 100%;
    }
    button {
      background: #FF5C00;
      padding: 0;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      color: #cccc;
      display: flex;
      align-items: center;
      justify-content: center;
      i,
      svg {
        width: 100%;
        color: #fff;
        font-size: 25px;
      }
    }
  }
  .content-ticket {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px 5px;
    .ticket {
      border-radius: 10px;
      text-align: right;
      width: 70%;
      margin: 5px 5px 5px auto;
      padding: 5px;
      box-shadow: 0px 0px 5px 2px #00000020;
      background: #9f9add36;
      min-width: 280px;
      .date {
        color: #6c757d;
        margin-left: 5px;
        font-weight: 600;
        font-size: 10px !important;
        text-transform: capitalize;
      }
      .description {
        font-size: 16px;
        font-weight: 600;
        padding: 5px;
        text-align: left;
      }
      .name {
        display: none;
      }
      &.support {
        margin: 5px auto 5px 5px;
        text-align: left;
        background-color: #fff;
        .name {
          color: #6c757d;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(15px, 2vw, 17px);
          text-transform: capitalize;
          display: block;
        }
      }
    }
  }
  .footer-ticket {
    box-shadow: 0px -3px 5px -2px #00000035;
    width: 100%;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
